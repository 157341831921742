import TextField from '@mui/material/TextField';
import CollapsibleReports from 'components/CollapsibleReports';
import InputDate from 'components/InputDate';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { isEmpty } from 'lodash';
import IPacking, { IPackingBody } from 'model/packing';
import { useEffect, useState } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import packingService from 'services/agricultural/packing-service';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const Package = ({ t }: WithTranslation) => {
  const [deliveryDate, setDeliveryDate] = useState<string>('');
  const [assocampos, setAssocampos] = useState<string>('');
  const [packagingControlData, setPackagingControlData] = useState<IPacking[]>([]);
  const [packingListGiveBack, setPackingListGiveBack] = useState<IPacking[]>([]);

  const handleControlData = () => {
    if (packingListGiveBack.length > 0) {
      const formatedPackingListGiveBack = packingListGiveBack.map(packing => {
        packing.DT_DEVOLUCAO = deliveryDate;
        packing.QT_UNIDADE = StringUtils.maskAmount(packing.QT_UNIDADE);
        const newPack: IPackingBody = {
          DT_DEVOLUCAO: deliveryDate,
          QT_UNIDADE: StringUtils.maskAmount(packing.QT_UNIDADE),
          CD_PESSOA: userRegistration,
          IE_EMBALAGEM: packing.DS_EMBALAGEM === 'PLÁSTICAS' ? 'P' : 'M',
          CD_UNIDADE_PADRAO: packing.CD_UNIDADE_PADRAO,
          QT_DEVOLVIDA: packing.QT_DEVOLVIDA,
          NR_ASSOCAMPOS: assocampos,
        };
        return newPack;
      });

      packingService
        .giveBackPackages({
          itens: formatedPackingListGiveBack,
        })
        .then(() => {
          setDeliveryDate('');
          setAssocampos('');
          getPackaging();
        });
    }
  };

  useEffect(() => {
    getPackaging();
  }, []);

  const getPackaging = () => {
    if (userRegistration != null) {
      packingService.getPackagingControlData(userRegistration).then(setPackagingControlData);
    }
  };

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.control')} />
      </StyledPageTitleContainer>
      <StyledText>
        <Trans i18nKey="agricultural.controlBalancePackages" />
      </StyledText>
      <InputsContainer>
        <StyledFilterPackageContainer>
          <InputDate label={t('agricultural.deliveryDate')} getDate={value => setDeliveryDate(value)} />
          <StyledInputText
            style={{ width: '231px' }}
            label={t('agricultural.ASSOCAMPOSNumber')}
            placeholder="Ex: 9999"
            onChange={({ target }) => setAssocampos(target.value)}
            variant="outlined"
            focused
            type={'number'}
          />
          <StyledFilterContainer filterWidth={215}>
            <StyledButton
              color="frisiaPrimary"
              style={{ width: '172px', alignSelf: 'center', marginTop: '3px' }}
              onClick={() => handleControlData()}
              disabled={isEmpty(deliveryDate) || isEmpty(assocampos) || packingListGiveBack.length < 1}
            >
              <span>{t('agricultural.confirm')}</span>
            </StyledButton>
          </StyledFilterContainer>
        </StyledFilterPackageContainer>
      </InputsContainer>
      <CollapsibleReports
        title={t('agricultural.packageControl')}
        tableData={packagingControlData}
        packagingToReturn={packing => {
          const indexPacking = packingListGiveBack.findIndex(item => item.ROWNUM === packing.ROWNUM);
          if (packing.QT_DEVOLVIDA === 0 && indexPacking !== -1) {
            return packingListGiveBack.splice(indexPacking, 1);
          }
          indexPacking !== -1 ? (packingListGiveBack[indexPacking] = packing) : setPackingListGiveBack([...packingListGiveBack, packing]);
        }}
      />
    </>
  );
};

const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
`;

const StyledInputText = styled(TextField)`
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    min-width: 200px;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 7px;
  }
  input {
    font-size: 16px;
    color: #656565;
    &::placeholder {
      opacity: 1;
    }
  }
`;

const StyledText = styled.div`
  text-align: left;
  margin-bottom: 25px;
`;

const StyledFilterPackageContainer = styled.div`
  display: flex;
  column-gap: 30px;
  row-gap: 30px;
`;

export default withTranslation()(Package);
